import { ReadFileBase64 } from '@/utils/files'
import { TABLA } from '@/utils/consts'

export default {
  async insert (Vue, formData, idcomercial) {
    const respPresupuestocli = await Vue.$api.call(
      'presupuestocli.insert',
      {
        values: {
          idcliente: formData.cliente?.idcliente || null,
          idsistema: formData.sistema?.idsistema || null,
          idcliente_potencial: formData.cliente_potencial?.idcliente_potencial || null,
          fpresupuesto: formData.fpresupuesto,
          idfpago_sistema: formData.idfpago_sistema,
          idfpago_cuotas: formData.idfpago_cuotas,
          idcondicion_pago: formData.idcondicion_pago,
          idcondicion_pago_cuota: formData.idcondicion_pago_cuota,
          idtactuacion_tsistema: formData.tactuacion_tsistema?.idtactuacion_tsistema || null,
          idcliente_direccion: formData.cliente_direccion?.idcliente_direccion || null,
          idcomercial,
          idagente_comercial: formData.idagente_comercial,
          idcanal_comunicacion: formData.idcanal_comunicacion,
          idcanal_conocimiento: formData.idcanal_conocimiento,
          idtipo_riesgo: formData.tipo_riesgo?.idtipo_riesgo || null,
          idgrado_seguridad: formData.idgrado_seguridad,
          idcliente_factura: formData.cliente_factura?.idcliente_factura || null,
          titulo: formData.titulo,
          descripcion_sistema: formData.descripcion_sistema,
          observaciones: formData.observaciones,
          notas_internas: formData.notas_internas,
        },
      },
    )
    const presupuestocli = respPresupuestocli.data.result.dataset[0]
    let apiCalls = await Vue.$online.dato.batchCallInsertDeFormData(presupuestocli.idpresupuestocli, formData.datosPresupuestoCliente)
    for (const file of formData.ficheros) {
      apiCalls.push({
        name: 'ficheroInsert',
        method: 'fichero.insert',
        params: {
          values: {
            data: await ReadFileBase64(file),
            nombre_fichero: file.name,
            idtabla: TABLA.presupuestocli.idtabla,
            id: presupuestocli.idpresupuestocli,
            idtfichero: formData.idtfichero,
            observaciones: null,
          },
        },
      })
    }
    const apiCallsPlantilla = await Vue.$online.plantillaArticulo.batchCallinsertDeFormData(formData, presupuestocli.idpresupuestocli)
    await Vue.$api.batchCall(apiCalls.concat(apiCallsPlantilla))
    return presupuestocli
  },
}
